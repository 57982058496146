<div *ngIf="!displayTrip">
  <ng-select
    *ngIf="rows"
    [loading]="loadingIndicator"
    [loadingText]="Loading"
    [clearable]="false"
    [(ngModel)]="selectedAsset"
  >
    <ng-option *ngFor="let c of rows" [value]="c.identifier">{{
      c.assetDisplay?.name
    }}</ng-option>
  </ng-select>
</div>
<div class="row" *ngIf="displayTrip">
  <button
    (click)="onBack()"
    type="button"
    class="btn btn-flat-primary"
    rippleEffect
  >
    <span [data-feather]="'arrow-left'" [class]="'mr-25'"></span>Back
  </button>
  <span style="margin-left: 20px; margin-top: 10px">
    <b> {{ itemSelected }}</b>
  </span>
</div>
<div *ngIf="rows && rows.length > 0" class="row">
  <div class="col-12">
    <div style="margin-top: 5px" class="row">
      <div class="col-6">
        <ng2-flatpickr
          placeholder="Select Date"
          [(ngModel)]="selectedStartDate"
          [config]="startDateOptions"
          name="DateRange"
        >
        </ng2-flatpickr>
      </div>
      <div style="font-size: small" class="col-6">
        <ng2-flatpickr
          placeholder="Select Date"
          [(ngModel)]="selectedEndDate"
          [config]="endDateOptions"
          name="DateRange"
        >
        </ng2-flatpickr>
      </div>
    </div>
  </div>
</div>

<button
  [disabled]="loadingIndicator"
  *ngIf="rows && rows.length > 0"
  (click)="onGetTrip()"
  type="button"
  class="btn btn-primary btn-block"
  rippleEffect
  style="border-radius: 2px; margin-top: 10px"
>
  {{ loadingIndicator ? "LOADING TRIP" : "GET TRIP" }}
</button>

<div *ngIf="loadingIndicator" class="col-md-12">
  <div class="text-center">
    <h4 class="loading-text">Loading Trips</h4>
    <div
      class="spinner-grow loading-text"
      style="width: 3rem; height: 3rem"
      role="status"
    ></div>
  </div>
</div>

<div class="col-md-12">
  <div *ngIf="searchInfo && searchInfo.length != 0">
    <table class="table table-sm text-muted font-small-2">
      <tr>
        <td>Total Distance</td>
        <td>{{ searchInfo | TripSummary : "AD" }} Km</td>
        <td>No. of Trips</td>
        <td>{{ searchInfo.length }}</td>
      </tr>
      <tr>
        <td>Total Average Speed</td>
        <td>{{ searchInfo | TripSummary : "AS" }} Km/hr</td>
        <td>
          Show All
          <input
            style="margin-left: 5px; margin-top: 3px"
            type="checkbox"
            (change)="onCheckboxChange($event)"
          />
        </td>
      </tr>
      <tr>
        <td>Total Duration</td>
        <td>
          {{ searchInfo | TripSummary : "TD" }}
        </td>
        <td></td>
      </tr>
    </table>
  </div>
</div>

<div
  *ngIf="searchInfo"
  class="ps"
  style="position: relative; max-width: 600px; max-height: 400px"
  [perfectScrollbar]="config"
>
  <div
    *ngFor="let t of searchInfo"
    class="card shadow-none bg-transparent border-info"
    [ngClass]="{ even: t.checked, odd: !t.checked }"
  >
    <div (click)="viewTrip(t)" class="card-body on-hover">
      <div class="row">
        <div class="co-md-4">
          <i data-feather="circle" class="font-medium-6 mr-1 text-success"></i>
          <small style="font-size: 10px; font-weight: bold">
            {{
              t.tripViewModel[t.tripViewModel.length - 1].gpsDateTime
                | amFromUtc
                | amLocal
                | amDateFormat : "YYYY-MM-DD h:mm:ss a"
            }}</small
          >
        </div>
        <div style="margin-left: 5px" class="co-md-8">
          <div
            [autoClose]="'outside'"
            ngbTooltip="{{
              t.tripViewModel[t.tripViewModel.length - 1]?.locationText
            }}"
          >
            <small>
              {{
                t.tripViewModel[t.tripViewModel.length - 1]?.locationText
                  .length > 12
                  ? (t.tripViewModel[t.tripViewModel.length - 1]?.locationText
                      | slice : 0 : 33) + ".."
                  : t.tripViewModel[t.tripViewModel.length - 1]?.locationText
              }}
            </small>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="co-md-4">
          <i data-feather="circle" class="font-medium-6 mr-1 text-warning"></i>
          <small style="font-size: 10px; font-weight: bold">
            {{
              t.tripViewModel[0].gpsDateTime
                | amFromUtc
                | amLocal
                | amDateFormat : "YYYY-MM-DD h:mm:ss  a"
            }}
          </small>
        </div>
        <div style="margin-left: 5px" class="co-md-8">
          <div
            [autoClose]="'outside'"
            ngbTooltip="{{ t.tripViewModel[0].locationText }}"
          >
            <small>
              {{
                t.tripViewModel[0].locationText.length > 12
                  ? (t.tripViewModel[0].locationText | slice : 0 : 33) + ".."
                  : t.tripViewModel[0].locationText
              }}
            </small>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="mr badge badge-light-primary text-muted font-small-2">
          {{ t.distanceCovered }}
          Km
        </div>
        <div class="mr badge badge-light-success text-muted font-small-2">
          {{ t.averageSpeed }}
          km/hr
        </div>
        <div class="mr badge badge-light-info text-muted font-small-2">
          {{ t.tripDuration }}
        </div>
      </div>
    </div>
  </div>
</div>
<ngb-alert
  *ngIf="(!searchInfo || searchInfo.length == 0) && isloaded"
  [type]="'warning'"
  [dismissible]="false"
>
  <div class="alert-body">No Trips Found</div>
</ngb-alert>
