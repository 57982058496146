
    <div class="section" style="margin-right: 10px" *ngIf="searchInfo">
        <div *ngIf="searchInfo.length !=0">
            <table class="table table-sm text-muted font-small-2">
                <tr>
                    <td>
                        Total Distance
                    </td>
                    <td>{{searchInfo | TripSummary: 'AD'}} Km</td>
                    <td>
                       No. of Trips
                    </td>
                    <td>{{searchInfo.length}}</td>
                </tr>
                <tr>
                    <td>
                        Total Average Speed
                    </td>
                    <td>
                        {{searchInfo | TripSummary: 'AS'}} Km/hr
                    </td>
                    <td>Show All
                        <input style="margin-left: 5px; margin-top: 3px;" type="checkbox"
                            (change)="onCheckboxChange($event)" />
                    </td>
                </tr>
                <tr>
                    <td>
                        Total Duration
                    </td>
                    <td>
                        {{searchInfo | TripSummary: 'TD'}}
                    </td>
                    <td></td>
                </tr>              
            </table>
        </div>
        <ul *ngFor="let t of searchInfo" class="timeline">
            <li (click)="viewTrip(t)" class="timeline-item select">
                <span class="timeline-point timeline-point-indicator"></span>
                <div class="timeline-event">

                    <div class="d-flex justify-content-start flex-sm-row flex-column mb-sm-0 mb-1">
                        <table>
                            <tr>
                                <td style="font-size: 9px; min-width: 100px;font-weight: bold;">

                                    {{(t.tripViewModel[t.tripViewModel.length - 1].gpsDateTime) | amFromUtc | amLocal
                                    |amDateFormat:'YYYY-MM-DD h:mm:ss a'}}
                                </td>
                                <td></td>
                                <td>
                                    <i data-feather="circle" class="font-medium-6 mr-1 text-success"></i>
                                </td>
                            </tr>
                            <tr>
                                <td style="font-size: 9px;min-width: 100px; font-weight: bold;">
                                    {{t.tripViewModel[0].gpsDateTime | amFromUtc | amLocal| amDateFormat: 'YYYY-MM-DD h:mm:ss a'}}
                                </td>
                                <td></td>
                                <td>
                                  
                                    <i data-feather="circle" class="font-medium-8 mr-1 text-warning"></i>
                                </td>
                            </tr>
                        </table>
                        <span class="timeline-event-time">
                            <table style="line-height: 18px;">
                                <tr style="color: #465157; text-transform: uppercase; font-size: 10px;">
                                    <td>
                                        {{(t.tripViewModel[t.tripViewModel.length - 1].locationText)}}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="color: #465157; text-transform: uppercase; font-size: 10px;">
                                        {{t.tripViewModel[0].locationText }}
                                    </td>
                                </tr>
                            </table>
                        </span>
                    </div>

                    <div class="d-flex justify-content-between flex-wrap flex-sm-row flex-column"
                        style="margin-top: 11px;">
                        <div>

                            <div style="font-size: 8px;" class="d-flex align-items-center">
                                <div class="mr badge badge-light-primary text-muted font-small-2">{{t.distanceCovered}}
                                    Km
                                </div>
                                <div class="mr badge badge-light-success text-muted font-small-2">{{t.averageSpeed}}
                                    km/hr
                                </div>
                            <div class="mr badge badge-light-info text-muted font-small-2">{{t.tripDuration}}</div>
                            </div>

                        </div>
                    </div>
                    <hr>
                </div>
            </li>
        </ul>
    </div>
    <ngb-alert *ngIf="(!searchInfo || searchInfo.length==0 ) && isloaded" [type]="'warning'" [dismissible]="false">
        <div class="alert-body">
            No Trips Found
        </div>
    </ngb-alert>