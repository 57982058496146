import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";

import { AuthService } from "./auth.service";
import { EndpointBase } from "./endpoint-base.service";
import { ConfigurationService } from "./configuration.service";

@Injectable()
export class ReportingEndpoint extends EndpointBase {
  get reportsUrl() {
    return this.configurations.gatewayUrlForReports + "/api/reports";
  }
  get reportingUrl() {
    return "http://localhost:9100/api/reportsettings/newlogo";
  }
  //get reportsUrl() { return "http://localhost:9300/api/reports" }
  get influxUrl() {
    return this.configurations.gatewayUrlForReports + "/v1/api/influx";
  }

  // get influxUrl() { return "http://localhost:9300/v1/api/influx"}
  constructor(
    private configurations: ConfigurationService,
    http: HttpClient,
    authService: AuthService
  ) {
    super(http, authService);
  }
  getNewLogoEndpoint<T>(newObject: any): Observable<T> {
    return this.http
      .post<T>(
        this.reportingUrl,
        JSON.stringify(newObject),
        this.requestHeaders
      )
      .pipe<T>(
        catchError((error) => {
          return this.handleError(error, () =>
            this.getNewLogoEndpoint(newObject)
          );
        })
      );
  }

  getTripsReportEndpoint<T>(newObject: any): Observable<T> {
    const endpointUrl = `${this.influxUrl}/tripreport`;
    return this.http
      .post<T>(endpointUrl, JSON.stringify(newObject), this.requestHeaders)
      .pipe<T>(
        catchError((error) => {
          return this.handleError(error, () =>
            this.getTripsReportEndpoint(newObject)
          );
        })
      );
  }
  getEventsToolingEndpoint<T>(newObject: any): Observable<T> {
    const endpointUrl = `${this.influxUrl}/tools`;
    return this.http
      .post<T>(endpointUrl, JSON.stringify(newObject), this.requestHeaders)
      .pipe<T>(
        catchError((error) => {
          return this.handleError(error, () =>
            this.getEventsToolingEndpoint(newObject)
          );
        })
      );
  }

  getEventsReportEndpoint<T>(newObject: any): Observable<T> {
    const endpointUrl = `${this.influxUrl}/eventsreport`;
    return this.http
      .post<T>(endpointUrl, JSON.stringify(newObject), this.requestHeaders)
      .pipe<T>(
        catchError((error) => {
          return this.handleError(error, () =>
            this.getEventsReportEndpoint(newObject)
          );
        })
      );
  }

  getTelemetryToolingEndpoint<T>(newObject: any): Observable<T> {
    const endpointUrl = `${this.influxUrl}/telemetry`;
    return this.http
      .post<T>(endpointUrl, JSON.stringify(newObject), this.requestHeaders)
      .pipe<T>(
        catchError((error) => {
          return this.handleError(error, () =>
            this.getTelemetryToolingEndpoint(newObject)
          );
        })
      );
  }

  getStopsStatsReportEndpoint<T>(newObject: any): Observable<T> {
    const endpointUrl = `${this.influxUrl}/stops`;
    return this.http
      .post<T>(endpointUrl, JSON.stringify(newObject), this.requestHeaders)
      .pipe<T>(
        catchError((error) => {
          return this.handleError(error, () =>
            this.getStopsStatsReportEndpoint(newObject)
          );
        })
      );
  }

  getAssetInfoEndpoint<T>(newObject: any): Observable<T> {
    const endpointUrl = `${this.reportsUrl}/assetinfo`;
    return this.http
      .post<T>(endpointUrl, JSON.stringify(newObject), this.requestHeaders)
      .pipe<T>(
        catchError((error) => {
          return this.handleError(error, () =>
            this.getAssetInfoEndpoint(newObject)
          );
        })
      );
  }

  getOnlineStatsEndpoint<T>(newObject: any): Observable<T> {
    const endpointUrl = `${this.reportsUrl}/online`;
    return this.http
      .post<T>(endpointUrl, JSON.stringify(newObject), this.requestHeaders)
      .pipe<T>(
        catchError((error) => {
          return this.handleError(error, () =>
            this.getOnlineStatsEndpoint(newObject)
          );
        })
      );
  }

  getOfflineStatsEndpoint<T>(newObject: any): Observable<T> {
    const endpointUrl = `${this.reportsUrl}/offline`;
    return this.http
      .post<T>(endpointUrl, JSON.stringify(newObject), this.requestHeaders)
      .pipe<T>(
        catchError((error) => {
          return this.handleError(error, () =>
            this.getOfflineStatsEndpoint(newObject)
          );
        })
      );
  }

  getLastLocationEndpoint<T>(newObject: any): Observable<T> {
    const endpointUrl = `${this.reportsUrl}/lastlocation`;
    return this.http
      .post<T>(endpointUrl, JSON.stringify(newObject), this.requestHeaders)
      .pipe<T>(
        catchError((error) => {
          return this.handleError(error, () =>
            this.getLastLocationEndpoint(newObject)
          );
        })
      );
  }

  getDistanceEndpoint<T>(newObject: any): Observable<T> {
    const endpointUrl = `${this.reportsUrl}/distance`;
    return this.http
      .post<T>(endpointUrl, JSON.stringify(newObject), this.requestHeaders)
      .pipe<T>(
        catchError((error) => {
          return this.handleError(error, () =>
            this.getDistanceEndpoint(newObject)
          );
        })
      );
  }

  getStateEndpoint<T>(newObject: any): Observable<T> {
    const endpointUrl = `${this.reportsUrl}/state`;
    return this.http
      .post<T>(endpointUrl, JSON.stringify(newObject), this.requestHeaders)
      .pipe<T>(
        catchError((error) => {
          return this.handleError(error, () =>
            this.getStateEndpoint(newObject)
          );
        })
      );
  }

  getStopsEndpoint<T>(newObject: any): Observable<T> {
    const endpointUrl = `${this.influxUrl}/stops`;

    return this.http
      .post<T>(endpointUrl, JSON.stringify(newObject), this.requestHeaders)
      .pipe<T>(
        catchError((error) => {
          return this.handleError(error, () =>
            this.getStopsEndpoint(newObject)
          );
        })
      );
  }

  getViolationEndpoint<T>(newObject: any): Observable<T> {
    const endpointUrl = `${this.influxUrl}/violationsreport`;

    return this.http
      .post<T>(endpointUrl, JSON.stringify(newObject), this.requestHeaders)
      .pipe<T>(
        catchError((error) => {
          return this.handleError(error, () =>
            this.getViolationEndpoint(newObject)
          );
        })
      );
  }

  getInputOutputEndpoint<T>(newObject: any): Observable<T> {
    const endpointUrl = `${this.influxUrl}/inputoutputreport`;

    return this.http
      .post<T>(endpointUrl, JSON.stringify(newObject), this.requestHeaders)
      .pipe<T>(
        catchError((error) => {
          return this.handleError(error, () =>
            this.getInputOutputEndpoint(newObject)
          );
        })
      );
  }

  //   getUpdateAssetEndpoint<T>(newObject: any, assetId:number): Observable<T> {
  //     const endpointUrl = `${this.assetUrl}/${assetId}`;

  //     return this.http.put<T>(endpointUrl, JSON.stringify(newObject), this.requestHeaders).pipe<T>(
  //       catchError(error => {
  //         return this.handleError(error, () => this.getUpdateAssetEndpoint(newObject,assetId));
  //       }));
  //   }
  //   getDeleteAssetEndpoint<T>(assetId: number): Observable<T> {
  //     const endpointUrl = `${this.assetUrl}/${assetId}`;
  //     return this.http.delete<T>(endpointUrl, this.requestHeaders).pipe<T>(
  //       catchError(error => {
  //         return this.handleError(error, () => this.getDeleteAssetEndpoint(assetId));
  //       }));
  //   }

  //   getAssetEndpoint<T>(deviceId:number,page?: number, pageSize?: number): Observable<T> {
  //     const endpointUrl = page && pageSize ? `${this.assetUrl}/${deviceId}/${page}/${pageSize}` :  `${this.assetUrl}/${deviceId}`;
  //     return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
  //       catchError(error => {
  //         return this.handleError(error, () => this. getAssetEndpoint(deviceId,page, pageSize));
  //       }));
  //   }
}
